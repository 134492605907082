type MergeError = {
    kind: "TypeMismatch" | "FailureState" | "InvalidArray";
    key: string;
};

const mergeInternal = <T extends Record<string, any>>(
    sources: Record<string, string | number | T | T[]>[],
): Record<string, string | number | T | T[]> => {
    let result: Record<string, string | number | T | T[]> = {};
    const allKeys = sources.map((s) => Object.keys(s)).flat();
    for (const k of allKeys) {
        const unFlat = sources.map((s) => s[k] ?? []);
        const values = unFlat.flat() as (string | number | T)[];

        if (values.length > 0) {
            if (checkType(values)) {
                const r = values.at(-1);
                if (typeof r === "string") {
                    if (r === "%%FAIL%%") {
                        throw { kind: "FailureState", key: k };
                    }
                    result[k] = r;
                } else if (typeof r === "number") {
                    result[k] = r;
                } else {
                    const keys = values.flat().flatMap((v) => Object.keys(v));
                    if (unFlat.find((c) => Array.isArray(c) && c.length > 0)) {
                        if (
                            values.reduce(
                                (acc, c) =>
                                    acc &&
                                    typeof c === "object" &&
                                    keys.reduce(
                                        (acc, k) => acc && k in c,
                                        true,
                                    ),
                                true,
                            )
                        ) {
                            result[k] = mergeArray(unFlat as T[][]);
                        } else {
                            throw { kind: "InvalidArray", key: k };
                        }
                    } else {
                        try {
                            // If `r` is an object, then all of the values must be objects
                            result[k] = mergeInternal(values as T[]) as T;
                        } catch (err) {
                            const error = err as MergeError;
                            throw { ...error, key: `${k}.${error.key}` };
                        }
                    }
                }
            }
        } else {
            throw { kind: "TypeMismatch", key: k };
        }
    }
    return result;
};

export const loadJsonOrEmpty = (
    fileName: string,
): Promise<Record<string, any>> => {
    return import("./" + fileName)
        .catch(() => Promise.resolve({}))
        .then((v) => {
            const res = { ...v };
            delete res.default;
            return res;
        });
};

export const merge = async (
    marketCode: string,
    businessCode: string,
    languageCode: string,
) => {
    const languageFile = await loadJsonOrEmpty(`${languageCode}.json`);
    const marketFile = await loadJsonOrEmpty(`_${marketCode}.json`);
    const businessFile = await loadJsonOrEmpty(`--${businessCode}.json`);
    const languageMarketFile = await loadJsonOrEmpty(
        `${languageCode}_${marketCode}.json`,
    );
    const marketBusinessFile = await loadJsonOrEmpty(
        `_${marketCode}--${businessCode}.json`,
    );
    const languageBusinessFile = await loadJsonOrEmpty(
        `${languageCode}--${businessCode}.json`,
    );
    const languageBusinessMarketFile = await loadJsonOrEmpty(
        `${languageCode}_${marketCode}--${businessCode}.json`,
    );
    try {
        return mergeInternal([
            languageFile,
            marketFile,
            businessFile,
            languageMarketFile,
            marketBusinessFile,
            languageBusinessFile,
            languageBusinessMarketFile,
        ]);
    } catch (err) {
        const error = err as MergeError;
        throw `Merge Error of Type ${error.kind} at key ${error.key}`;
    }
};
const checkType = <T extends Record<string, any>>(
    values: (string | number | T)[],
) => {
    if (values.length > 0) {
        const expected = typeof values[0];
        return values.reduce((acc, c) => acc && typeof c === expected, true);
    }
    return true;
};

const mergeArray = <T extends Record<string, any>>(unmerged: T[][]): T[] => {
    // unmerged is a List of objects we need the merge result to be
    // the right most of EACH value
    // However we will need to reverse it because order matters and the use wants the order to match the entry in the array
    // in the json
    const headDict = unmerged.flat().at(0);
    if (headDict && "value" in headDict) {
        return [
            ...unmerged
                .flat()
                .reduceRight((acc, cur) => {
                    if (!acc.has(cur.value)) acc.set(cur.value, cur);
                    return acc;
                }, new Map<String, T>())
                .values(),
        ].reverse();
    }

    const values = unmerged.reduceRight(
        (acc, cur) =>
            cur.reduce((acc, innerCur, index) => {
                const lookup = innerCur.value ?? index;
                if (!acc.has(lookup)) {
                    acc.set(lookup, innerCur);
                }

                return acc;
            }, acc),
        new Map<String, T>(),
    );

    return [...values.values()];
};

// This is exported so we can test with simple JSON objects.
export const mergeTest = (jsons: Record<string, any>[]) => {
    return mergeInternal(jsons);
};
