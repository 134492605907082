import { gtmSendEvent } from "@wojo/analytics";
import { Button, Text } from "@wojo/ui";
import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useInternalAuth } from "../../client/context/InternalAuthContextProvider";
import { AuthPaths } from "../router/AuthPaths";

export const ResetPasswordFooter: React.FC = () => {
    const { state } = useLocation();
    const email = state?.email;
    const navigate = useNavigate();
    const { loginOrigin } = useInternalAuth();

    return (
        <Text
            style={{
                fontWeight: "var(--g-font-weight-medium)",
                textAlign: "center",
            }}
        >
            Not interested in using the quick access link next time?&nbsp;
            <Button
                style={{
                    marginLeft: "var(--g-spacing-xs)",
                    lineHeight: "var(--g-spacing-lg)",
                }}
                variant="quiet"
                onClick={async () => {
                    gtmSendEvent({
                        event: "track",
                        ga4Tracking: {
                            eventCategory: "login",
                            eventAction: `${loginOrigin} no password account`,
                            eventLabel: "click reset password",
                            ga4_object: "LOGIN",
                            ga4_action: "RESET_PASSWORD",
                            loginId: `${loginOrigin}NoPasswordAccount`,
                            magicLink: "yes",
                        },
                    });

                    navigate(AuthPaths.SetPassword, { state: { email } });
                    return;
                }}
            >
                Reset your password
            </Button>
        </Text>
    );
};
