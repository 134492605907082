import { gtmSendEvent } from "@wojo/analytics";
import { Button } from "@wojo/ui";
import React from "react";
import { FiChevronLeft } from "react-icons/fi";
import { useLocation, useNavigate } from "react-router-dom";
import { useInternalAuth } from "../../client/context/InternalAuthContextProvider";
import { AuthPaths } from "../router/AuthPaths";

export const BackButton: React.FC = () => {
    const navigate = useNavigate();
    const { pathname } = useLocation();
    const { loginOrigin, lastFilledField, setLastFilledField } =
        useInternalAuth();

    switch (pathname) {
        case "/":
            return null;
    }
    return (
        <Button
            adornLeft={<FiChevronLeft />}
            variant="quiet"
            onClick={() => {
                if (pathname === AuthPaths.CreateAccount) {
                    gtmSendEvent({
                        event: "track",
                        ga4Tracking: {
                            ga4_object: "LEAD_FORM",
                            ga4_action: "ABANDONED",
                            abandonmentField: lastFilledField || "None",
                            leadFormId: `registration${loginOrigin}`,
                        },
                    });
                    setLastFilledField?.("");
                }
                gtmSendEvent({
                    event: "track",
                    ga4Tracking: {
                        eventCategory: "login",
                        eventAction: `from view ${pathname.replace("/", "")}`,
                        eventLabel: "back button",
                        ga4_object: "LOGIN",
                        ga4_action: "BACK_BUTTON",
                        loginId: `${loginOrigin}PasswordAccount`,
                    },
                });
                navigate(-1);
            }}
        >
            Back
        </Button>
    );
};
