import { RegisterOptions } from "react-hook-form";

export const getMaxLength = (
    maxLength?: RegisterOptions["maxLength"] | number | string,
): number | null => {
    if (!maxLength) {
        return null;
    }
    if (typeof maxLength === "string" || typeof maxLength === "number") {
        return Number(maxLength);
    }
    return Number(maxLength.value);
};
