import { ApolloError } from "@apollo/client";
import React from "react";
import { FormError } from "./FormError";

type Props = { error?: ApolloError };

export const GraphqlFormError: React.FC<Props> = ({ error }) => {
    const message =
        error?.graphQLErrors.at(0)?.message ?? error?.networkError?.message;
    if (!message) {
        return null;
    }
    return <FormError message={message} />;
};
