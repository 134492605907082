import { Button, Text } from "@wojo/ui";
import React from "react";
import { useInternalAuth } from "../../client/context/InternalAuthContextProvider";
import { getAccountUrl } from "../../client/hooks/get-account-url";

export const SetPasswordSuccessView: React.FC = () => {
    const { setIsModalOpen, webSource } = useInternalAuth();
    const accountUrl = getAccountUrl(webSource);

    return (
        <div
            style={{
                display: "grid",
                justifyContent: "center",
                textAlign: "center",
            }}
        >
            <Text
                style={{
                    fontWeight: "var(--g-font-weight-bold)",
                    marginBottom: "var(--g-spacing-xl)",
                    maxWidth: 300,
                }}
            >
                Success! Your password has been updated.
            </Text>
            <div>
                <Button
                    style={{
                        justifySelf: "center",
                        marginRight: "var(--g-spacing-md)",
                    }}
                    onClick={() => setIsModalOpen?.(false)}
                >
                    Continue
                </Button>
                <Button variant="quiet" tag="a" href={accountUrl}>
                    View account
                </Button>
            </div>
        </div>
    );
};
