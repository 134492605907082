import { useI18n } from "@wojo/localization";
import { Text } from "@wojo/ui";
import React from "react";
import { useLocation } from "react-router-dom";
import { useInternalAuth } from "../../client/context/InternalAuthContextProvider";
import { CreateAccountForm } from "../forms/CreateAccountForm";
import { Title } from "../title";

export const CreateAccountView: React.FC = () => {
    const { loginOrigin, webSource } = useInternalAuth();
    const { global } = useI18n("global");
    const { state } = useLocation();
    const email = state?.email;

    return (
        <>
            <Title>{global.auth.createAccount.heading.l()}</Title>
            <Text
                style={{
                    marginBottom: "var(--g-spacing-md)",
                }}
            >
                {(() => {
                    if (loginOrigin === "wishlist" && email) {
                        return global.auth.createAccount.fromWishlistPrompt.l({
                            email,
                        });
                    } else if (email) {
                        const content =
                            webSource === "INDIES"
                                ? global.auth.createAccount.emailDoesNotExist.l() +
                                  " " +
                                  global.auth.createAccount.registerIndies.l()
                                : global.auth.createAccount.emailDoesNotExist.l() +
                                  " " +
                                  global.auth.createAccount.registerGroups.l();
                        return content;
                    } else {
                        const content =
                            webSource === "INDIES"
                                ? global.auth.createAccount.registerIndies.l()
                                : global.auth.createAccount.registerGroups.l();
                        return content;
                    }
                })()}
            </Text>
            <CreateAccountForm />
            <Text.Body
                variant="3"
                style={{
                    marginTop: "var(--g-spacing-md",
                }}
            >
                {global.auth.createAccount.footnote.l()}
            </Text.Body>
        </>
    );
};
