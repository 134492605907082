"use client";
import { PatternFormat, PatternFormatProps } from "react-number-format";
import {
    FieldDescription,
    getFieldDescriptionId,
} from "../../form-fields/field-description";
import { FieldLabel } from "../../form-fields/field-label";
import { FieldProps } from "../../form-fields/field-props";
import { FieldWrapper } from "../../form-fields/field-wrapper";
import { Textbox, TextboxProps } from "../textbox";
import React from "react";

export type MaskedNumericFieldProps = PatternFormatProps &
    FieldProps &
    Omit<TextboxProps, "hasError" | "onChange" | "value" | "ref"> & {
        /**
         * Force controlled mode sets the initial value to empty string
         */
        forceControlledMode?: boolean;
        /**
         * The type of the input field
         */
        type?: "tel" | "password" | "text";
    };
/**
 * Input masking based on https://www.npmjs.com/package/react-number-format
 */
export const MaskedNumericField = React.forwardRef<
    HTMLInputElement,
    MaskedNumericFieldProps
>((props, ref) => {
    const {
        label,
        hideLabel,
        required = false,
        errorText,
        descriptionText,
        hideErrorText,
        value = "",
        forceControlledMode,
        showOptionalValidationText,
        hideMarker,
        hideErrorIcon,
        fieldId,
        disabled,
        defaultValue,
        type,
        maxLength,
        mask = "_",
        patternChar = "_",
        format,
        ...inputProps
    } = props;

    const hasError = !!errorText;

    return (
        <FieldWrapper
            disabled={disabled}
            data-has-error={hasError}
            fieldLabel={
                <FieldLabel
                    hideLabel={hideLabel}
                    required={required}
                    fieldId={fieldId}
                    hideMarker={hideMarker}
                    showOptionalValidationText={showOptionalValidationText}
                    hasError={hasError}
                    disabled={disabled}
                >
                    {label}
                </FieldLabel>
            }
            fieldDescription={
                <FieldDescription
                    fieldId={fieldId}
                    errorText={errorText}
                    hideErrorIcon={hideErrorIcon}
                    hideErrorText={hideErrorText}
                    disabled={disabled}
                >
                    {descriptionText}
                </FieldDescription>
            }
        >
            <PatternFormat
                {...inputProps}
                aria-describedby={getFieldDescriptionId(fieldId)}
                required={required}
                getInputRef={ref}
                id={fieldId}
                hasError={hasError}
                value={forceControlledMode || value ? value : undefined}
                disabled={disabled}
                format={format}
                mask={mask}
                patternChar={patternChar}
                customInput={Textbox}
                valueIsNumericString
                maxLength={maxLength}
            />
        </FieldWrapper>
    );
});

MaskedNumericField.displayName = "MaskedNumericField";
