"use client";

import { FC } from "react";
import { useFormContext } from "react-hook-form";
import { Button } from "../button";

type InnerProps = React.ButtonHTMLAttributes<HTMLButtonElement>;

export const SubmitButton: FC<InnerProps> = (props) => {
    const formContext = useFormContext();
    const isSubmitting = formContext.formState.isSubmitting;
    const { children, ...otherProps } = props;

    return (
        <Button {...otherProps} loading={isSubmitting} disabled={isSubmitting}>
            {children}
        </Button>
    );
};
