import { gtmSendEvent } from "@wojo/analytics";
import { Button, Text } from "@wojo/ui";
import React from "react";
import { useNavigate } from "react-router-dom";
import { useInternalAuth } from "../../client/context/InternalAuthContextProvider";
import { AuthPaths } from "../router/AuthPaths";

export const SendResetPasswordLinkFooter: React.FC<{ email: string }> = ({
    email,
}) => {
    const navigate = useNavigate();
    const { loginOrigin } = useInternalAuth();

    return (
        <Text
            style={{
                fontWeight: "var(--g-font-weight-medium)",
                textAlign: "center",
            }}
        >
            Can&apos;t remember your password?&nbsp;
            <Button
                variant="quiet"
                onClick={async () => {
                    gtmSendEvent({
                        event: "track",
                        ga4Tracking: {
                            eventCategory: "login",
                            eventAction: `${loginOrigin} password account`,
                            eventLabel: "click reset password",
                            ga4_object: "LOGIN",
                            ga4_action: "RESET_PASSWORD",
                            loginId: `${loginOrigin}PasswordAccount`,
                            magicLink: "no",
                        },
                    });

                    navigate(AuthPaths.SendResetPassword, {
                        state: { email },
                    });
                }}
            >
                Reset your password
            </Button>
        </Text>
    );
};
