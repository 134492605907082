import React from "react";
import { SetPasswordForm } from "../forms/SetPasswordForm";
import { Title } from "../title";

export const ResetPasswordView: React.FC = () => {
    return (
        <>
            <Title>Reset your account password</Title>
            <SetPasswordForm />
        </>
    );
};
