export const magicLinkAllowList = [
    "gat-app://magic-link",
    "gat-app-qa://magic-link",
    "ub-app://magic-link",
    "ub-app-qa://magic-link",
    "adv-app://magic-link",
    "adv-app-qa://magic-link",
    "gat-app://reset-password",
    "gat-app-qa://reset-password",
    "ub-app://reset-password",
    "ub-app-qa://reset-password",
    "adv-app://reset-password",
    "adv-app-qa://reset-password",
    "https://goaheadtours.ca",
    "https://goaheadtours.com",
    "https://efultimatebreak.com",
    "https://www.goaheadtours.ca",
    "https://www.goaheadtours.com",
    "https://www.efultimatebreak.com",
    "https://groups.goaheadtours.ca",
    "https://groups.goaheadtours.com",
    "https://groups.efultimatebreak.com",
    "https://groups.efadventures.com",
    "https://gat-us.wojo-web.tours",
    "https://gat-ca.wojo-web.tours",
    "https://ub-us.wojo-web.tours",
    "https://adv-us.wojo-web.tours",
    "https://gat-groups-us.wojo-web.tours",
    "https://gat-groups-ca.wojo-web.tours",
    "https://ub-groups-us.wojo-web.tours",
    "https://adv-groups-us.wojo-web.tours",
];
