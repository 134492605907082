import { gtmSendEvent } from "@wojo/analytics";
import { useI18n } from "@wojo/localization";
import {
    Form,
    FormCheckbox,
    FormMaskedNumericField,
    FormPasswordField,
    FormTextField,
    SubmitButton,
} from "@wojo/ui";
import type { E164Number } from "libphonenumber-js";
import React, { useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { useLocation, useNavigate } from "react-router-dom";
import { useSignupCustomerMutation } from "../../../generated/graphql";
import { useInternalAuth } from "../../client/context/InternalAuthContextProvider";
import { getAccountUrl } from "../../client/hooks/get-account-url";
import { useIntersectionObserver } from "../../client/hooks/use-intersection-observer";
import { FormError } from "../error";
import { GraphqlFormError } from "../error/GraphqlFormError";
import { AuthPaths } from "../router/AuthPaths";
import { isEmpty } from "lodash";
//HACK: don't import hook from root index.ts file: https://ef-wojo.atlassian.net/browse/WWR-1569
import { useSubmitWebform } from "@wojo/webforms/src/use-submit-webform";
import { useGcDepartureId } from "../../client/reactive-vars";

type FormValues = {
    firstName?: string;
    lastName?: string;
    email: string;
    phone?: E164Number;
    password?: string;
    communicationOptIn?: boolean;
};

export const CreateAccountForm: React.FC = () => {
    const {
        loginOrigin,
        setView,
        displayType,
        webSource,
        signin,
        setLastFilledField,
        smsOptInDefault,
    } = useInternalAuth();
    const { state } = useLocation();
    const navigate = useNavigate();
    const accountUrl = getAccountUrl(webSource);
    const [loginError, setLoginError] = useState<string | undefined>(undefined);
    const [hasLoginError, setHasLoginError] = useState<boolean>(false);
    const [createAccount, { error }] = useSignupCustomerMutation();
    const createAccountFormRef = useRef<HTMLDivElement | null>(null);
    const gcDepartureId = useGcDepartureId();
    const createAccountFormEntry = useIntersectionObserver(
        createAccountFormRef,
        {
            threshold: 1,
        },
    );
    const inViewport = !!createAccountFormEntry?.isIntersecting;
    const submitWebform = useSubmitWebform(gcDepartureId);
    const { global } = useI18n("global");

    useEffect(() => {
        if (error) {
            gtmSendEvent({
                event: "track",
                ga4Tracking: {
                    ga4_object: "LEAD_FORM",
                    ga4_action: "REGISTRATION_FAILED",
                    leadFormId: `registration${loginOrigin}`,
                },
            });
        }
    }, [error, loginOrigin]);

    const createAccountForm = useForm<FormValues>({
        defaultValues: {
            email: state?.email ?? "",
            password: "",
            communicationOptIn: smsOptInDefault,
        },
        mode: "onChange",
        criteriaMode: "all",
        reValidateMode: "onChange",
    });

    useEffect(() => {
        if (createAccountForm.formState.isDirty) {
            gtmSendEvent({
                event: "track",
                ga4Tracking: {
                    ga4_object: "LEAD_FORM",
                    ga4_action: "STARTED",
                    leadFormId: `registration${loginOrigin}`,
                },
            });
        }
    }, [createAccountForm.formState.isDirty, loginOrigin]);

    useEffect(() => {
        if (inViewport) {
            gtmSendEvent({
                event: "track",
                ga4Tracking: {
                    ga4_object: "LEAD_FORM",
                    ga4_action: "VIEWED",
                    leadFormId: `registration${loginOrigin}`,
                },
            });
        }
    }, [inViewport, loginOrigin]);

    return (
        <div ref={createAccountFormRef}>
            <Form<FormValues>
                form={createAccountForm}
                onSubmit={async (values: FormValues) => {
                    setHasLoginError(false);
                    gtmSendEvent({
                        event: "track",
                        ga4Tracking: {
                            ga4_object: "LEAD_FORM",
                            ga4_action: "SUBMITTED",
                            leadFormId: `registration${loginOrigin}`,
                        },
                    });

                    try {
                        const { data } = await createAccount({
                            variables: {
                                input: {
                                    firstName: values.firstName!,
                                    lastName: values.lastName!,
                                    email: values.email,
                                    phoneNumber: values.phone || undefined,
                                    password: values.password,
                                },
                            },
                        });
                        if (
                            data?.signupCustomer.__typename !==
                            "CustomerSignupResult"
                        ) {
                            setHasLoginError(true);
                            if (
                                data?.signupCustomer.__typename ===
                                "DuplicateUniqueKeyError"
                            ) {
                                setLoginError(
                                    global.auth.createAccount.emailIsDuplicate.l(),
                                );
                            }
                            return;
                        }

                        await submitWebform({
                            formName: "CreateAccount",
                            department:
                                webSource === "GROUPS"
                                    ? "Group Member"
                                    : "Indies",
                            customerId: data.signupCustomer.customer.customerId,
                            email: values.email,
                            firstName: values.firstName,
                            lastName: values.lastName,
                            phone: values.phone,
                            emailOptIn: values.communicationOptIn,
                            smsOptIn: values.communicationOptIn,
                        });
                        await signin?.();

                        setLastFilledField?.("");
                        gtmSendEvent({
                            event: "track",
                            ga4Tracking: {
                                eventCategory: "login",
                                eventAction: `${loginOrigin} register now`,
                                eventLabel: "submission SUCCEED",
                                ga4_object: "LOGIN",
                                ga4_action: "LOGGED_IN",
                                loginId: `${loginOrigin}PasswordAccount`,
                                maginLink: "no",
                            },
                        });
                        setView?.({
                            view: AuthPaths.LoginSuccess,
                            routerState: { email: values.email },
                            onLoginSuccessPath:
                                displayType === "FULL_PAGE"
                                    ? accountUrl
                                    : undefined,
                        });
                    } catch (e) {
                        gtmSendEvent({
                            event: "track",
                            ga4Tracking: {
                                ga4_object: "LEAD_FORM",
                                ga4_action: "REGISTRATION_FAILED",
                                leadFormId: `registration${loginOrigin}`,
                            },
                        });

                        const existingAccountError = (
                            e as { graphQLErrors?: { status: number }[] }
                        ).graphQLErrors?.some(({ status }) => status === 409);

                        if (existingAccountError) {
                            navigate(AuthPaths.LoginAccountExists, {
                                state: {
                                    email: values.email,
                                },
                            });
                            return;
                        }
                    }
                }}
            >
                <div
                    style={{
                        display: "grid",
                        gridGap: "var(--g-spacing-lg)",
                        paddingTop: "var(--g-spacing-xs)",
                    }}
                >
                    <FormTextField
                        name="firstName"
                        label={global.formFieldLabels.firstName.l()}
                        validation={{
                            required:
                                global.validationErrors.firstNameRequired.l(),
                        }}
                        onChange={() => {
                            setLastFilledField?.("First name");
                        }}
                    />
                    <FormTextField
                        name="lastName"
                        label={global.formFieldLabels.lastName.l()}
                        validation={{
                            required:
                                global.validationErrors.lastNameRequired.l(),
                        }}
                        onChange={() => {
                            setLastFilledField?.("Last name");
                        }}
                    />
                    <FormTextField
                        name="email"
                        validation={{
                            required: global.validationErrors.emailRequired.l(),
                            pattern: {
                                value: /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,
                                message:
                                    global.validationErrors.emailInvalid.l(),
                            },
                        }}
                        label={global.formFieldLabels.email.l()}
                        onChange={() => {
                            setLastFilledField?.("Email address");
                        }}
                    />
                    <FormMaskedNumericField
                        format={global.inputMasks.phone.l()}
                        validation={{
                            required: false,
                            pattern: {
                                value: new RegExp(global.regex.phone.l()),
                                message:
                                    global.validationErrors.phoneInvalid.l(),
                            },
                        }}
                        name="phone"
                        label={global.formFieldLabels.phone.l()}
                        descriptionText={global.formFieldDescriptions.phone.l()}
                        onChange={() => {
                            setLastFilledField?.("Phone number");
                        }}
                    />
                    <FormPasswordField
                        name="password"
                        enableRequirements
                        label={global.formFieldLabels.password.l()}
                        data-testid="password"
                        onChange={() => {
                            setLastFilledField?.("Password");
                        }}
                    />
                    <FormCheckbox
                        name="communicationOptIn"
                        label={global.formFieldLabels.communicationOptIn.l()}
                        onChange={() => {
                            setLastFilledField?.("Communication Opt In");
                        }}
                    />
                    <SubmitButton
                        style={{ justifySelf: "start" }}
                        data-testid="create-account-submit-button"
                    >
                        {global.genericSubmitCta.l()}
                    </SubmitButton>
                    {hasLoginError && (
                        <FormError
                            message={
                                !isEmpty(loginError) ? loginError : undefined
                            }
                        />
                    )}
                    <GraphqlFormError error={error} />
                </div>
            </Form>
        </div>
    );
};
