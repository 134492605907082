"use client";
import cn from "clsx";
import React, { PropsWithChildren, useEffect } from "react";
import { useFormContext } from "react-hook-form";
import { FiCheck, FiX } from "react-icons/fi";
import styles from "./FormPasswordRequirements.module.scss";

type RequirementProps = PropsWithChildren & {
    isFulfilled: boolean;
};
const Requirement: React.FC<RequirementProps> = ({
    isFulfilled = false,
    children,
}) => {
    const Icon = isFulfilled ? FiCheck : FiX;
    return (
        <div
            className={cn([
                { [styles["is-fulfilled"]]: isFulfilled },
                styles["requirements-wrapper"],
            ])}
        >
            <Icon
                role="img"
                aria-label={isFulfilled ? "Passed: " : "Failed: "}
                title={isFulfilled ? "Passed: " : "Failed: "}
            />
            <div>{children}</div>
        </div>
    );
};

export type FormPasswordRequirementsProps = {
    name: string;
    visible?: boolean;
} & React.HTMLAttributes<HTMLDivElement>;
export const FormPasswordRequirements: React.FC<
    FormPasswordRequirementsProps
> = ({ name, visible = true, ...divProps }) => {
    const {
        formState: { errors },
        trigger,
    } = useFormContext();
    useEffect(() => {
        visible && trigger(name);
    }, [name, trigger, visible]);
    const controlErrors = errors[name]?.types;
    if (!visible) {
        return null;
    }

    return (
        <div {...divProps}>
            <Requirement
                isFulfilled={!controlErrors?.hasOwnProperty("minimum")}
            >
                Must be at least 6 characters
            </Requirement>
            <Requirement
                isFulfilled={!controlErrors?.hasOwnProperty("capitals")}
            >
                Must include at least 1 capital letter
            </Requirement>
            <Requirement
                isFulfilled={!controlErrors?.hasOwnProperty("characters")}
            >
                Must include at least 1 number or special character
            </Requirement>
        </div>
    );
};
