import classNames from "clsx";
import { FC, HTMLAttributes, ReactNode } from "react";
import { FiAlertCircle } from "react-icons/fi";
import styles from "../shared/Field.module.scss";

export type DescriptionProps = {
    /**
     * Error messaging to display under the form field.  Presence triggers the error state
     */
    errorText?: string;
    /**
     * Hides the icon that displays with the error messaging
     * @default true
     */
    hideErrorIcon?: boolean;
    /**
     * Hides the error text
     */
    hideErrorText?: boolean;
    /**
     * Whether or not this element should use disabled style
     */
    disabled?: boolean;
    /**
     * Unique id of the field this component wraps around
     */
    fieldId: string;
    /**
     * Character count component; does not get replaced when error message is displayed
     */
    characterCount?: ReactNode;
};

export type FieldDescriptionProps = DescriptionProps &
    HTMLAttributes<HTMLDivElement>;
// The the `null` behavior matches the previous `null` behavior in the `FieldWrapper` and other components.
export const getFieldDescriptionId = (fieldId?: string) =>
    `${fieldId}_FIELD_DESCRIPTION`;

/**
 * Stand alone description component.  Use instead of FieldWrapper if finer control of bottom text is needed
 */
export const FieldDescription: FC<FieldDescriptionProps> = ({
    fieldId,
    errorText,
    hideErrorIcon = true,
    disabled,
    children,
    hideErrorText,
    characterCount,
    ...divProps
}) => {
    const hasError = !!errorText;
    return children || (hasError && !hideErrorText) || characterCount ? (
        <div
            className={classNames(styles.description, {
                [styles.invalid]: hasError && !disabled,
                [styles.disabled]: disabled,
            })}
            id={getFieldDescriptionId(fieldId)}
            {...divProps}
        >
            {!hasError && children}
            {!hideErrorText && hasError && (
                <p className={styles.error}>
                    {!hideErrorIcon && (
                        <FiAlertCircle
                            role="img"
                            title="Error"
                            aria-label="Error"
                            color="currentColor"
                            className={styles["error__icon"]}
                        />
                    )}
                    {errorText}
                </p>
            )}
            {characterCount}
        </div>
    ) : null;
};
