import { FC, LabelHTMLAttributes } from "react";
import styles from "../shared/Field.module.scss";

import classNames from "clsx";

export type LabelProps = {
    /**
     * If this form field must be filled out
     */
    required?: boolean;
    /**
     * Hides the required/optional indicator
     * @default false
     */
    hideMarker?: boolean;
    /**
     * shows the optional validation text
     * @default false
     */
    showOptionalValidationText?: boolean;
    /**
     * Whether the field should display in an error state
     */
    hasError: boolean | undefined;
    /**
     * Whether or not this element should use disabled style
     */
    disabled?: boolean;
    /**
     * Unique id of the field this component wraps around
     */
    fieldId: string;
    /**
     * Hides the label visually
     */
    hideLabel?: boolean;
};

export type FieldLabelProps = LabelProps &
    LabelHTMLAttributes<HTMLLabelElement>;

/**
 * Stand alone label component.  Use instead of FieldWrapper if finer control of label component is needed
 */
export const FieldLabel: FC<FieldLabelProps> = ({
    fieldId,
    required,
    hideMarker,
    disabled,
    children,
    showOptionalValidationText,
    hasError,
    className,
    hideLabel,
    ...labelProps
}) => {
    return (
        <label
            className={classNames(styles.label, className, {
                [styles.invalid]: hasError && !disabled,
                [styles.disabled]: disabled,
                "visually-hidden": hideLabel,
            })}
            htmlFor={fieldId}
            {...labelProps}
        >
            {children}
            {!hideMarker && (required || showOptionalValidationText) && (
                <span className={styles.marker} aria-hidden>
                    {required && "required"}
                    {showOptionalValidationText && !required && "optional"}
                </span>
            )}
        </label>
    );
};
