import React from "react";
import { LoginByMagicLinkOrPassword } from "./LoginByMagicLinkOrPassword";

export const LoginView: React.FC = () => {
    return (
        <LoginByMagicLinkOrPassword>
            We&apos;ll send you an email with a link that&apos;ll sign you in to
            your account instantly, no password needed.
        </LoginByMagicLinkOrPassword>
    );
};
