import { Button } from "@wojo/ui";
import styles from "./AuthPageLoginViewFooter.module.scss";

export const AuthPageLoginViewFooter: React.FC = () => {
    return (
        <div className={styles.footer}>
            <Button
                variant="quiet"
                tag="a"
                href={process.env.NEXT_PUBLIC_ZENDESK_AGENT_URL || ""}
            >
                Log in as EF agent
            </Button>
        </div>
    );
};
