import { useI18n } from "@wojo/localization";
import { Button, Markdown, Text } from "@wojo/ui";
import React from "react";
import { FiCheckCircle } from "react-icons/fi";
import { useLocation } from "react-router-dom";
import { useInternalAuth } from "../../client/context/InternalAuthContextProvider";
import { Title } from "../title";
import styles from "./SendLinkView.module.scss";
import { SendResetPasswordOrMagicLink } from "./SendResetPasswordOrMagicLink";

export const SendMagicLinkView: React.FC = () => {
    const { state } = useLocation();
    const email = state?.email;
    const noPassword = !!state && !state.hasPassword;
    const { setIsModalOpen, displayType } = useInternalAuth();
    const { global } = useI18n("global");

    return (
        <SendResetPasswordOrMagicLink
            email={email}
            emailTemplateId={
                process.env.NEXT_PUBLIC_MAGIC_LINK_TEMPLATE_ID ?? ""
            }
        >
            <Title>Log in</Title>
            <Text className={styles["success-text"]}>
                <FiCheckCircle
                    style={{ marginTop: "var(--g-spacing-xs)" }}
                    aria-hidden
                />
                <span>We&apos;ve sent a quick access link to {email}</span>
            </Text>
            <Text className={styles["success-description"]}>
                {global.auth.magicLinkSuccessDescription.l()}
            </Text>
            {noPassword && (
                <Markdown
                    className={styles["success-description"]}
                    markdown={global.auth.systemPasswordResetMessage.l()}
                />
            )}
            {displayType === "MODAL" && (
                <Button onClick={() => setIsModalOpen?.(false)}>Close</Button>
            )}
        </SendResetPasswordOrMagicLink>
    );
};
