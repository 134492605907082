import { Route, Routes } from "react-router-dom";
import { BackButton } from "../back-button";
import { AuthPaths } from "./AuthPaths";

export const AuthTitle = () => {
    return (
        <Routes>
            <Route path={AuthPaths.CreateAccount} Component={BackButton} />
            <Route path={AuthPaths.Login} Component={BackButton} />
            <Route path={AuthPaths.SetPassword} Component={BackButton} />
            <Route path={AuthPaths.LoginAccountExists} Component={BackButton} />
            <Route path={AuthPaths.ResetPassword} Component={BackButton} />
            <Route path={AuthPaths.SendMagicLink} Component={BackButton} />
            <Route path={AuthPaths.SendResetPassword} Component={BackButton} />
            <Route path="*" Component={null} />
        </Routes>
    );
};
