"use client";

import get from "lodash.get";
import React, { forwardRef, useImperativeHandle, useRef } from "react";
import { RegisterOptions, useFormContext } from "react-hook-form";

export type FormFieldProps<T> = React.PropsWithChildren<
    {
        /**
         * Name that will also be used as the field's value in the form values
         */
        name: string;
        /**
         * This will also automatically hook up required label and error text for the wrapped component
         */
        validation?: RegisterOptions;
    } & Omit<T, "errorText" | "required" | "hasError">
>;

export const FormField = forwardRef(function FormField<
    T extends Record<string, any>,
>(
    { name, validation, children }: FormFieldProps<T>,
    ref: React.Ref<T | undefined>,
): React.ReactElement<FormFieldProps<T>> {
    const {
        register,
        formState: { errors },
    } = useFormContext();
    const error = get(errors, name);
    const errorText = error?.message;
    const child = React.Children.only(children);
    const required = !!validation?.required;
    const registerProps = register(name, validation ?? {});

    const innerRef = useRef<T>();
    useImperativeHandle(ref, () => innerRef.current);

    return React.isValidElement(child) ? (
        React.cloneElement(
            child as any,
            {
                ...registerProps,
                ref: (el: T) => {
                    innerRef.current = el;
                    registerProps.ref(el);
                },
                required,
                onChange: (event) => {
                    child.props?.onChange?.(event);
                    registerProps.onChange(event);
                },
                onBlur: (event) => {
                    child.props?.onBlur?.(event);
                    registerProps.onBlur(event);
                },
                // HACK: This is a workaround for the fact that when using this component with an input DOM component, errorText should not be passed in
                // WWB-1000 for reference
                ...(child.type !== "input" ? { errorText } : {}),
            },
            null,
        )
    ) : (
        <>{children}</>
    );
});
