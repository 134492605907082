import React from "react";
import { IconBaseProps } from "react-icons";
import {
    FiAlertCircle,
    FiCheck,
    FiCheckCircle,
    FiClock,
    FiInfo,
} from "react-icons/fi";
import styles from "./AlertIcon.module.scss";
import { AlertStatus } from "./AlertProps";

export type AlertIconProps = {
    status: AlertStatus;
    altText?: string;
};

export const AlertIcon: React.FC<AlertIconProps> = ({ status, altText }) => {
    let defaultAltText = `${status}`;
    switch (status) {
        case "alert":
            defaultAltText = "Alert: ";
            break;
        case "info":
            defaultAltText = "Notice: ";
            break;
        case "confirmed":
            defaultAltText = "Confirmed: ";
            break;
        case "requested":
            defaultAltText = "Pending: ";
            break;
        case "success":
            defaultAltText = "Success: ";
            break;
    }
    const renderedAltText = altText ?? defaultAltText;

    const sharedIconProps: IconBaseProps = {
        className: styles.icon,
        "aria-label": renderedAltText ? renderedAltText : undefined,
        "aria-hidden": !renderedAltText,
        title: renderedAltText,
    };

    let icon = null;

    switch (status) {
        case "alert":
            icon = <FiAlertCircle {...sharedIconProps} />;
            break;
        case "info":
            icon = <FiInfo {...sharedIconProps} />;
            break;
        case "confirmed":
            icon = <FiCheck {...sharedIconProps} />;
            break;
        case "requested":
            icon = <FiClock {...sharedIconProps} />;
            break;
        case "success":
        default:
            icon = <FiCheckCircle {...sharedIconProps} />;
            break;
    }

    return <span className={styles["icon-wrapper"]}>{icon}</span>;
};
