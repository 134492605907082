import React, { PropsWithChildren } from "react";
import styles from "./HorizontalDivider.module.scss";

export const HorizontalDivider: React.FC<PropsWithChildren> = ({
    children,
}) => (
    <div className={styles.wrapper}>
        <div className={styles["horizontal-divider"]} />
        {children || <div className={styles["horizontal-divider"]} />}
        <div className={styles["horizontal-divider"]} />
    </div>
);
