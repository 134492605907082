import classnames from "clsx";
import { FC, ReactNode } from "react";
import styles from "../shared/Field.module.scss";

export type FieldWrapperProps = {
    children?: ReactNode;
    /**
     * Whether or not this element should use disabled style
     */
    disabled?: boolean;
    /**
     * The elements to display above the form field
     */
    fieldLabel?: ReactNode;
    /**
     * The elements to display below the form field
     */
    fieldDescription: ReactNode;
};

/**
 * Wraps child fields with styled label and description elements
 * Note: for accessibility, use appropriate attributes (e.g. id, aria-describedby, required, disabled)
 * on children elements to apply the attributes needed for programmatic associations
 */
export const FieldWrapper: FC<FieldWrapperProps> = ({
    disabled,
    children,
    fieldLabel,
    fieldDescription,
}) => {
    return (
        <div
            className={classnames(styles.wrapper, {
                [styles.disabled]: disabled,
            })}
        >
            {fieldLabel}
            {children}
            {fieldDescription}
        </div>
    );
};
