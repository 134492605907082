"use client";
import cn from "clsx";
import { HtmlHTMLAttributes } from "react";
import {
    CriteriaMode,
    DefaultValues,
    FormProvider,
    SubmitErrorHandler,
    SubmitHandler,
    useForm,
    UseFormReturn,
    ValidationMode,
} from "react-hook-form";
import styles from "./Form.module.scss";

export type FormProps<T extends Record<string, any>> = Omit<
    HtmlHTMLAttributes<HTMLFormElement>,
    "onSubmit" | "onError"
> & {
    /**
     * Returns values from the form submission. Uses the generic from the component
     */
    onSubmit?: SubmitHandler<T>;
    /**
     * Returns errors from the form submission. Uses the generic from the component
     */
    onErrors?: SubmitErrorHandler<T>;
    /**
     * Default values to hydrate the form with if you're not passing your own form instance
     */
    defaultValues?: DefaultValues<T>;
    /**
     * Whether to automatically space each form field
     */
    autoLayout?: boolean;
    /**
     * Pass in the result of a useForm hook if you want to control it yourself. Otherwise, one will be used
     */
    form?: UseFormReturn<T>;

    /**
     * Which action to validate on
     */
    mode?: keyof ValidationMode;

    /**
     * Which action to re-validate on
     */
    reValidateMode?: "onBlur" | "onChange" | "onSubmit";

    /**
     * Whether to show all errors or just the first one
     */
    criteriaMode?: CriteriaMode;
};

export function Form<T extends Record<string, any>>({
    onSubmit,
    onErrors,
    children,
    defaultValues,
    autoLayout = false,
    form,
    mode = "onSubmit",
    criteriaMode = "firstError",
    reValidateMode = "onChange",
    className,
    ...formProps
}: FormProps<T>) {
    const defaultForm = useForm<T>({
        defaultValues,
        mode,
        reValidateMode,
        criteriaMode,
    });
    const actualForm = form ?? defaultForm;
    const classNames = cn([{ [styles["auto-layout"]]: autoLayout }, className]);

    return (
        <FormProvider {...actualForm}>
            <form
                {...formProps}
                onSubmit={
                    onSubmit
                        ? actualForm.handleSubmit(onSubmit, onErrors)
                        : undefined
                }
                className={classNames}
            >
                {children}
            </form>
        </FormProvider>
    );
}
