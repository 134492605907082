import { gtmSendEvent } from "@wojo/analytics";
import { useI18n } from "@wojo/localization";
import { Form, FormPasswordField, SubmitButton } from "@wojo/ui";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useSigninCustomerWithPasswordMutation } from "../../../generated/graphql";
import { useInternalAuth } from "../../client/context/InternalAuthContextProvider";
import { FormError } from "../error";
import { GraphqlFormError } from "../error/GraphqlFormError";
import { AuthPaths } from "../router/AuthPaths";

type FormValues = {
    password: string;
};

export const LoginWithPasswordForm: React.FC = () => {
    const { state } = useLocation();
    const email = state?.email;
    const navigate = useNavigate();
    const [logIn, { error }] = useSigninCustomerWithPasswordMutation();
    const { loginOrigin, signin } = useInternalAuth();
    const [loginError, setLoginError] = useState<string | undefined>();
    const { global } = useI18n("global");

    useEffect(() => {
        if (!email) {
            navigate(AuthPaths.VerifyEmail);
        }
    }, [email, navigate]);

    if (!email) {
        return null;
    }

    return (
        <Form<FormValues>
            onSubmit={async (values) => {
                setLoginError(undefined);

                const { data } = await logIn({
                    variables: {
                        input: {
                            email,
                            password: values.password,
                        },
                    },
                    onError: () => {
                        gtmSendEvent({
                            event: "track",
                            ga4Tracking: {
                                eventCategory: "login",
                                eventAction: `${loginOrigin} password account`,
                                eventLabel: "password submit FAIL",
                                ga4_object: "LOGIN",
                                ga4_action: "LOGIN_FAILED",
                                loginId: `${loginOrigin}PasswordAccount`,
                                magicLink: "no",
                            },
                        });
                    },
                });

                if (
                    data?.signinCustomerWithPassword.__typename !==
                    "SigninResult"
                ) {
                    gtmSendEvent({
                        event: "track",
                        ga4Tracking: {
                            eventCategory: "login",
                            eventAction: `${loginOrigin} password account`,
                            eventLabel: "password submit FAIL",
                            ga4_object: "LOGIN",
                            ga4_action: "LOGIN_FAILED",
                            loginId: `${loginOrigin}PasswordAccount`,
                            magicLink: "no",
                        },
                    });

                    setLoginError(global.validationErrors.password.noMatch.l());
                    return;
                }
                await signin?.();
                gtmSendEvent({
                    event: "track",
                    ga4Tracking: {
                        eventCategory: "login",
                        eventAction: `${loginOrigin} password account`,
                        eventLabel: "password submit SUCCEED",
                        ga4_object: "LOGIN",
                        ga4_action: "LOGGED_IN",
                        loginId: `${loginOrigin}PasswordAccount`,
                        magicLink: "no",
                    },
                });
                navigate(AuthPaths.LoginSuccess, { state: { email } });
                return;
            }}
        >
            <div
                style={{
                    display: "grid",
                    gridGap: "var(--g-spacing-md)",
                }}
            >
                <FormPasswordField
                    name="password"
                    label={global.formFieldLabels.password.l()}
                    autoFocus
                    autoComplete="current-password"
                    validation={{
                        required: global.validationErrors.password.required.l(),
                    }}
                />
                <input
                    style={{ display: "none" }}
                    name="email"
                    autoComplete="username"
                    aria-hidden="true"
                    tabIndex={-1}
                    value={email}
                    readOnly
                    type="email"
                />
                {loginError && <FormError message={loginError} />}
                <GraphqlFormError error={error} />
                <SubmitButton
                    style={{
                        marginTop: "var(--g-spacing-sm)",
                        justifySelf: "start",
                    }}
                >
                    {global.genericSubmitCta.l()}
                </SubmitButton>
            </div>
        </Form>
    );
};
