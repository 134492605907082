"use client";

import get from "lodash.get";
import React, { ReactElement } from "react";
import {
    Controller,
    ControllerRenderProps,
    FieldValues,
    RegisterOptions,
    useFormContext,
} from "react-hook-form";

export type FormFieldControllerProps<T> = React.PropsWithChildren<
    {
        /**
         * Name that will also be used as the field's value in the form values
         */
        name: string;
        /**
         * This will also automatically hook up required label and error text for the wrapped component
         */
        validation?: RegisterOptions;
        /**
         * specify a custom format id for the field e.g. address.state
         */
        id?: string;
    } & Omit<T, "errorText" | "required" | "fieldId">
>;

export function FormFieldController<T extends Record<string, any>>({
    name,
    validation,
    children,
}: FormFieldControllerProps<T>): React.ReactElement<
    FormFieldControllerProps<T>
> {
    const {
        formState: { errors },
        control,
    } = useFormContext();
    const error = get(errors, name);
    const errorText = error?.message;
    const child = React.Children.only(children);
    const required =
        typeof validation?.required === "object"
            ? !!validation.required.value
            : !!validation?.required;

    return (
        <Controller
            rules={validation ?? {}}
            control={control}
            name={name}
            render={({ field }) =>
                React.isValidElement(child) ? (
                    React.cloneElement(
                        child as ReactElement<
                            ControllerRenderProps<FieldValues, string> & {
                                errorText: typeof errorText;
                                required: boolean;
                            }
                        >,
                        {
                            ...field,
                            errorText,
                            required,
                            onChange: (event: unknown) => {
                                child.props?.onChange?.(event);
                                field.onChange(event);
                            },
                            onBlur: ((event: unknown) => {
                                child.props?.onBlur?.(event);
                                field.onBlur();
                            }) as () => void,
                        },
                        null,
                    )
                ) : (
                    <>{children}</>
                )
            }
        />
    );
}
