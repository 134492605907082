import { useI18n } from "@wojo/localization";
import { Button, Text } from "@wojo/ui";
import React from "react";
import { useNavigate } from "react-router-dom";
import { useInternalAuth } from "../../client/context/InternalAuthContextProvider";
import { VerifyEmailForm } from "../forms/VerifyEmailForm";
import { AuthPaths } from "../router/AuthPaths";
import { Title } from "../title";

const RegistrationPrompt: React.FC = () => {
    const navigate = useNavigate();
    const { webSource } = useInternalAuth();
    const { global } = useI18n("global");
    const text =
        webSource === "INDIES"
            ? global.auth.registerPrompt.indies.l()
            : global.auth.registerPrompt.groups.l();

    return (
        <div>
            <Text.Body emphasis="2">
                {global.auth.registerPrompt.heading.l()}
            </Text.Body>
            <Text
                style={{
                    margin: "var(--g-spacing-sm) 0 var(--g-spacing-lg) 0",
                }}
                tag="p"
            >
                {text}
            </Text>
            <Button
                data-testid="register-button"
                onClick={() => navigate(AuthPaths.CreateAccount, { state: {} })}
            >
                {global.auth.registerPrompt.buttonLabel.l()}
            </Button>
        </div>
    );
};

export const VerifyEmailView: React.FC = () => {
    return (
        <>
            <Title>Log in</Title>
            <VerifyEmailForm />
            <div
                style={{
                    height: 1,
                    backgroundColor: "var(--s-color-separator-standard)",
                    margin: "var(--g-spacing-xl) 0",
                }}
            ></div>
            <RegistrationPrompt />
        </>
    );
};
