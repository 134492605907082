import { Button, Text } from "@wojo/ui";
import { useSearchParams } from "next/navigation";
import React, { useEffect } from "react";
import { useInternalAuth } from "../../client/context/InternalAuthContextProvider";
import { getAccountUrl } from "../../client/hooks/get-account-url";
import { magicLinkAllowList } from "../../constants";

export const LoginSuccessView: React.FC = () => {
    const {
        setIsModalOpen,
        onLoginSuccessPath,
        hideCTAs,
        displayType,
        webSource,
    } = useInternalAuth();
    const next = useSearchParams().get("next");
    const accountUrl = getAccountUrl(webSource);
    const shouldRedirect =
        !!next &&
        (next.startsWith("/") ||
            process.env.NODE_ENV === "development" ||
            magicLinkAllowList.some((url) => next.startsWith(url)));
    const suppressCTAs = shouldRedirect || !!hideCTAs;

    useEffect(() => {
        if (shouldRedirect) {
            window.location.href = next;
            // Remove auth query param, so navigation doesn't trigger login again
            const urlParams = new URLSearchParams(window.location.search);
            urlParams.delete("auth");
            const newUrl = `${
                window.location.pathname
            }?${urlParams.toString()}`;
            window.history.replaceState(null, "", newUrl);
        }
    }, [shouldRedirect, next]);

    return (
        <div
            style={{
                display: "grid",
                justifyContent: "center",
                textAlign: "center",
            }}
        >
            <Text.Heading
                order="4"
                style={{
                    marginBottom: "var(--g-spacing-md)",
                    maxWidth: 300,
                }}
            >
                All set! You&apos;re now logged in to your account.
            </Text.Heading>
            {!suppressCTAs && (
                <div style={{ display: "grid", rowGap: "var(--g-spacing-md)" }}>
                    <Button
                        type="button"
                        style={{
                            justifySelf: "center",
                        }}
                        onClick={() => {
                            setIsModalOpen?.(false);
                            if (onLoginSuccessPath) {
                                window.location.href = onLoginSuccessPath;
                            } else if (displayType === "FULL_PAGE") {
                                window.location.href = accountUrl;
                            }
                        }}
                    >
                        Continue
                    </Button>
                    <div>
                        <Button variant="secondary" tag="a" href={accountUrl}>
                            View account
                        </Button>
                    </div>
                </div>
            )}
        </div>
    );
};
