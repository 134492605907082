"use client";
import React from "react";
import { Controller, get, useFormContext } from "react-hook-form";
import { FormFieldControllerProps } from "../form-field-controller";
import {
    MaskedNumericField,
    MaskedNumericFieldProps,
} from "../../text-fields/masked-numeric-field";
import { NumberFormatValues } from "react-number-format";
import { getMaxLength } from "../hooks";

export type FormMaskedNumericFieldProps =
    FormFieldControllerProps<MaskedNumericFieldProps>;

export const FormMaskedNumericField: React.FC<FormMaskedNumericFieldProps> = ({
    validation,
    name,
    maxLength,
    ...textFieldProps
}) => {
    const max = getMaxLength(validation?.maxLength) ?? maxLength;
    const {
        formState: { errors },
        control,
    } = useFormContext();
    const error = get(errors, name);
    const errorText = error?.message;
    const required =
        typeof validation?.required === "object"
            ? !!validation.required.value
            : !!validation?.required;

    return (
        <Controller
            rules={validation ?? {}}
            control={control}
            name={name}
            render={({ field }) => {
                const { onChange, ...fieldProps } = field;
                return (
                    <MaskedNumericField
                        {...fieldProps}
                        {...textFieldProps}
                        errorText={errorText}
                        required={required}
                        onValueChange={(value: NumberFormatValues) => {
                            field.onChange(value.formattedValue);
                        }}
                        fieldId={name}
                        maxLength={max}
                        onChange={undefined}
                        value={field.value?.replace(/[^0-9]/g, "") ?? ""}
                        valueIsNumericString
                    />
                );
            }}
        />
    );
};
