import { gtmSendEvent } from "@wojo/analytics";
import QueryString from "qs";
import React, { PropsWithChildren, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useSendMagicLinkMutation } from "../../../generated/graphql";
import { useInternalAuth } from "../../client/context/InternalAuthContextProvider";
import { getAccountUrl } from "../../client/hooks/get-account-url";
import { AuthPaths } from "../router/AuthPaths";

/**
 * @description This component is responsible for sending a magic link to the user's email to reset their password or login with the magic link.
 */
export const SendResetPasswordOrMagicLink: React.FC<
    PropsWithChildren<{
        emailTemplateId: string;
        email: string;
        isResetPassword?: boolean;
    }>
> = ({ email, emailTemplateId, isResetPassword, children }) => {
    const { onLoginSuccessPath, displayType, loginOrigin, webSource } =
        useInternalAuth();
    const navigate = useNavigate();
    const accountUrl = getAccountUrl(webSource);
    const [sendMagicLink] = useSendMagicLinkMutation();

    useEffect(() => {
        let isSubscribed = true;

        const origin =
            process.env.NODE_ENV === "development"
                ? "https://goaheadtours.com" //service has a whitelist of hardcoded urls. hardcode this to allow local testing
                : window.location.origin;

        // we don't need the pathName for full page login since we should never land on it again (from a magic link).
        const pathName =
            displayType === "FULL_PAGE" ? "" : window.location.pathname;

        // handles cases where the path might be a full url including the origin e.g. https://goaheadtours.com/checkout/xyz/customize
        const returnUrlOrigin = onLoginSuccessPath?.startsWith("/")
            ? origin
            : "";

        const fullPageNext = {
            ...(displayType === "FULL_PAGE" && {
                // shouldn't land on the same login page after signing in, even if no next param is provided
                next: returnUrlOrigin + (onLoginSuccessPath ?? accountUrl),
            }),
        };
        const modalNext = {
            ...(onLoginSuccessPath &&
                displayType === "MODAL" && {
                    next: returnUrlOrigin + onLoginSuccessPath,
                }),
        };
        const qs = QueryString.stringify({
            ...fullPageNext,
            ...modalNext,
            ...(isResetPassword ? { auth: "reset-password" } : undefined),
            // The token param must be last since the wojo-api endpoint appends the token value at the end of the url
            token: "",
        });
        const returnURL = `${origin}${pathName}?${qs}`;

        const fetchData = async () => {
            try {
                if (isSubscribed) {
                    gtmSendEvent({
                        event: "track",
                        ga4Tracking: {
                            eventCategory: "login",
                            eventAction: `${loginOrigin}login no password account`,
                            eventLabel: "automatic access link",
                            ga4_object: "LOGIN",
                            ga4_action: "REQUEST_MAGIC_LINK",
                            loginId: `${loginOrigin}PasswordAccount`,
                            magicLink: "yes",
                        },
                    });

                    sendMagicLink({
                        variables: {
                            input: {
                                email,
                                emailTemplateId,
                                returnURL,
                                firstName: "",
                            },
                        },
                    }).then(({ errors }) => {
                        if (errors) {
                            isSubscribed &&
                                navigate(AuthPaths.CreateAccount, {
                                    state: {
                                        webConversionForm: "CreateAccount",
                                    },
                                });
                        }
                    });
                }
            } catch {}
        };
        fetchData().catch();
        return () => (isSubscribed = false) as any;
    }, [
        displayType,
        email,
        emailTemplateId,
        isResetPassword,
        navigate,
        onLoginSuccessPath,
        sendMagicLink,
        loginOrigin,
        accountUrl,
    ]);

    return <div>{children}</div>;
};
