import React from "react";
import { useLocation } from "react-router-dom";
import { AuthRouterState } from "../router/AuthRouter";
import { ResetPasswordFooter } from "./ResetPasswordFooter";
import { SendResetPasswordLinkFooter } from "./SendResetPasswordLinkFooter";

export const LoginWithMagicLinkSuccessFooter: React.FC = () => {
    const {
        state: { email, hasPassword },
    }: { state: AuthRouterState } = useLocation();

    return hasPassword ? (
        <ResetPasswordFooter />
    ) : (
        <SendResetPasswordLinkFooter email={email!} />
    );
};
