import cn from "clsx";
import React, {
    ComponentPropsWithRef,
    createElement,
    ForwardedRef,
    forwardRef,
    HTMLAttributes,
} from "react";
import styles from "./Card.module.scss";

type ValidHTMLTags = "button" | "a" | "div" | "section";

type ReactComponent = Exclude<React.ElementType, keyof JSX.IntrinsicElements>;

type ValidTags = ValidHTMLTags | ReactComponent;

export type CardProps<T extends ValidTags> = ComponentPropsWithRef<T> &
    HTMLAttributes<HTMLOrSVGElement> & {
        /**
         * Determines the roundness of the corners
         * @default standard
         */
        variant?: "small" | "standard";
        className?: string;

        /**
         * Alternate JSX element to use for the button
         * @default div
         */
        tag?: T | ValidTags;
    };

const DEFAULT_TAG = "div" as const;

export const InnerCard = <T extends ValidTags = typeof DEFAULT_TAG>(
    {
        tag = DEFAULT_TAG,
        variant = "standard",
        className,
        ...rest
    }: CardProps<T>,
    ref: React.ForwardedRef<HTMLOrSVGElement>,
) => {
    const classes = cn(
        styles.wrapper,
        styles[`wrapper--${variant}`],
        styles[`wrapper--button`],
        styles[`wrapper--button--${variant}`],
        className,
    );

    return (
        <>
            {createElement(tag, {
                ...rest,
                className: classes,
                ref,
            })}
        </>
    );
};

export const Card = forwardRef(InnerCard) as <
    T extends ValidTags = typeof DEFAULT_TAG,
>(
    props: CardProps<T> & { ref?: ForwardedRef<any> },
) => ReturnType<typeof InnerCard>;
