import { Button, Text } from "@wojo/ui";
import React, { PropsWithChildren } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { LoginWithPasswordForm } from "../forms/LoginWithPasswordForm";
import { HorizontalDivider } from "../horizontal-divider";
import { AuthPaths } from "../router/AuthPaths";
import { Title } from "../title";

export const LoginByMagicLinkOrPassword: React.FC<PropsWithChildren> = ({
    children,
}) => {
    const { state } = useLocation();
    const navigate = useNavigate();

    return (
        <div
            style={{
                display: "grid",
            }}
        >
            <Title>Log in</Title>
            <Text
                style={{
                    margin: "var(--g-spacing-sm) 0 var(--g-spacing-lg) 0",
                }}
            >
                {children}
            </Text>
            <Button
                style={{
                    marginBottom: 16,
                    width: "max-content",
                }}
                onClick={async () => {
                    navigate(AuthPaths.SendMagicLink, { state });
                }}
            >
                Get access link
            </Button>
            <HorizontalDivider>
                <Text.Body style={{ padding: "0  var(--g-spacing-md)" }}>
                    or
                </Text.Body>
            </HorizontalDivider>
            <Text
                style={{
                    fontWeight: "var(--g-font-weight-bold)",
                    paddingTop: "var(--g-spacing-sm)",
                    paddingBottom: "var(--g-spacing-sm)",
                }}
            >
                Prefer to use a password?
            </Text>
            <LoginWithPasswordForm />
        </div>
    );
};
