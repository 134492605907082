import { Route, Routes } from "react-router-dom";
import { CreateAccountView } from "../views/CreateAccountView";
import { LoginAccountExistsView } from "../views/LoginAccountExistsView";
import { LoginSuccessView } from "../views/LoginSuccessView";
import { LoginView } from "../views/LoginView";
import { MagicLinkExpiredView } from "../views/MagicLinkExpiredLinkView";
import { ResetPasswordLinkExpiredView } from "../views/ResetPasswordLinkExpiredView";
import { ResetPasswordView } from "../views/ResetPasswordView";
import { SendMagicLinkView } from "../views/SendMagicLinkView";
import { SendResetPasswordView } from "../views/SendResetPasswordView";
import { SetPasswordSuccessView } from "../views/SetPasswordSuccessView";
import { SetPasswordView } from "../views/SetPasswordView";
import { VerifyEmailCheckoutView } from "../views/VerifyEmailCheckoutView";
import { VerifyEmailSavedTripView } from "../views/VerifyEmailSavedTripView";
import { VerifyEmailView } from "../views/VerifyEmailView";
import { VerifyEmailWishlistView } from "../views/VerifyEmailWishlistView";
import { AuthPaths } from "./AuthPaths";

export const AuthBody: React.FC = () => {
    return (
        <Routes>
            <Route path={AuthPaths.VerifyEmail} Component={VerifyEmailView} />
            <Route
                path={AuthPaths.VerifyEmailCheckout}
                Component={VerifyEmailCheckoutView}
            />
            <Route
                path={AuthPaths.VerifyEmailWishlist}
                Component={VerifyEmailWishlistView}
            />
            <Route
                path={AuthPaths.VerifyEmailSavedTrip}
                Component={VerifyEmailSavedTripView}
            />
            <Route
                path={AuthPaths.CreateAccount}
                Component={CreateAccountView}
            />
            <Route path={AuthPaths.Login} Component={LoginView} />
            <Route
                path={AuthPaths.LoginAccountExists}
                Component={LoginAccountExistsView}
            />
            <Route path={AuthPaths.LoginSuccess} Component={LoginSuccessView} />
            <Route path={AuthPaths.SetPassword} Component={SetPasswordView} />
            <Route
                path={AuthPaths.SetPasswordSuccess}
                Component={SetPasswordSuccessView}
            />
            <Route
                path={AuthPaths.ResetPassword}
                Component={ResetPasswordView}
            />
            <Route
                path={AuthPaths.SendMagicLink}
                Component={SendMagicLinkView}
            />
            <Route
                path={AuthPaths.LoginWithMagicLinkSuccess}
                Component={LoginSuccessView}
            />
            <Route
                path={AuthPaths.ResetPasswordLinkExpired}
                Component={ResetPasswordLinkExpiredView}
            />
            <Route
                path={AuthPaths.MagicLinkExpired}
                Component={MagicLinkExpiredView}
            />
            <Route
                path={AuthPaths.SendResetPassword}
                Component={SendResetPasswordView}
            />
        </Routes>
    );
};
