import React from "react";
import { LoginByMagicLinkOrPassword } from "./LoginByMagicLinkOrPassword";

export const LoginAccountExistsView: React.FC = () => {
    return (
        <LoginByMagicLinkOrPassword>
            Looks like you&apos;ve already got an online account associated with
            that email address! We&apos;ll send you an email with a link
            that&apos;ll sign you in to your account instantly, no password
            needed.
        </LoginByMagicLinkOrPassword>
    );
};
