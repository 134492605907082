import cn from "clsx";
import React, { DetailedHTMLProps, InputHTMLAttributes } from "react";
import styles from "./Textbox.module.scss";
type InnerProps = DetailedHTMLProps<
    InputHTMLAttributes<HTMLInputElement>,
    HTMLInputElement
>;

export type TextFieldAdornmentProps = InnerProps & {
    side?: "left" | "right";
    children: React.ReactNode;
};

export const TextFieldAdornment: React.FC<TextFieldAdornmentProps> = ({
    side,
    children,
    ...rest
}) => {
    if (!children) {
        return null;
    }
    return (
        <span
            {...rest}
            className={cn(styles.adornment, {
                [styles["disabled"]]: rest.disabled,
                [styles[`adornment--${side}`]]: side,
            })}
        >
            {children}
        </span>
    );
};
