import { Text } from "@wojo/ui";
import { PropsWithChildren } from "react";

export const Title: React.FC<PropsWithChildren> = ({ children }) => (
    <Text.Heading
        order="3"
        style={{
            marginBottom: "var(--g-spacing-md)",
        }}
    >
        {children}
    </Text.Heading>
);
