import { gtmSendEvent } from "@wojo/analytics";
import { Form, FormTextField, SubmitButton, Text } from "@wojo/ui";
import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useInternalAuth } from "../../client/context/InternalAuthContextProvider";
import { AuthPaths } from "../router/AuthPaths";
import { Title } from "../title";

export const MagicLinkExpiredView: React.FC = () => {
    const navigate = useNavigate();
    const { loginOrigin } = useInternalAuth();

    useEffect(() => {
        gtmSendEvent({
            event: "track",
            ga4Tracking: {
                ga4_object: "LOGIN",
                ga4_action: "EXPIRED",
                magicLink: "yes",
            },
        });
    }, []);
    return (
        <>
            <Title>Sorry, this link has expired.</Title>
            <Text style={{ marginBottom: "var(--g-spacing-md)" }}>
                Please enter your email address again and we&apos;ll send you a
                new quick access link. For security reasons, the link will
                expire in 20 minutes.
            </Text>
            <Form<{ email: string }>
                onSubmit={async (values) => {
                    gtmSendEvent({
                        event: "track",
                        ga4Tracking: {
                            eventCategory: "login",
                            eventAction: "magiclink",
                            eventLabel: "expired resend",
                            ga4_object: "LOGIN",
                            ga4_action: "MAGIC_LINK_RESEND",
                            loginId: `${loginOrigin}NoPasswordAccount`,
                            magicLink: "yes",
                        },
                    });
                    const { email } = values;
                    navigate(AuthPaths.SendMagicLink, { state: { email } });
                }}
            >
                <div
                    style={{
                        display: "grid",
                        gridTemplateColumns: "auto",
                        gridGap: "var(--g-spacing-md)",
                    }}
                >
                    <FormTextField
                        name="email"
                        validation={{
                            required: "Please enter your email address.",
                        }}
                        label="Email address"
                    />
                    <SubmitButton
                        style={{
                            marginTop: "var(--g-spacing-md)",
                            justifySelf: "start",
                        }}
                    >
                        Submit
                    </SubmitButton>
                </div>
            </Form>
        </>
    );
};
