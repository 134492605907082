"use client";
import cn from "clsx";
import { marked, Renderer } from "marked";
import { CSSProperties, FC, useEffect, useRef } from "react";
import textStyles from "../text/Text.module.scss";
import styles from "./Markdown.module.scss";

type InnerProps = React.DetailedHTMLProps<
    React.HTMLAttributes<HTMLDivElement>,
    HTMLDivElement
>;

export type MarkdownProps = InnerProps & {
    /**
     * The text to be rendered
     * @default false
     */
    markdown: string;
    /**
     * Determines if urls open in a new tab
     * @default false
     */
    linksAreExternal?: boolean;
    /**
     * Set the Color for the Markdown text
     */
    markdownColor?: string;
    /**
     * Determines if emails should be mailto links
     * @default true
     */
    emailsAreLinks?: boolean;
    /**
     * Callback function for when a link is clicked
     */
    onLinkClick?: (event: MouseEvent, anchor: HTMLAnchorElement) => void;
};

export const Markdown: FC<MarkdownProps> = ({
    markdown,
    markdownColor,
    linksAreExternal,
    emailsAreLinks,
    className,
    onLinkClick,
    ...props
}) => {
    const renderer = new Renderer();

    // sets text color for ALL Markdown
    const markdownColorClassName = {
        [styles["markdown--color"]]: markdownColor,
    };

    renderer.link = (href, title, text) => {
        if (href.startsWith("mailto:") && !emailsAreLinks) {
            return text;
        }
        const linkClassName = cn(
            textStyles["emphasis--1"],
            textStyles.text,
            textStyles["style--body-1"],
            styles["markdown--link"],
            markdownColorClassName,
        );
        const linkTitle = title ? `title="${title}"` : "";
        const linkTarget = linksAreExternal ? 'target="_blank"' : "";

        return `<a href=${href} ${linkTitle} class="${linkClassName}" ${linkTarget}>${text}</a>`;
    };

    renderer.heading = (text, order) => {
        const headingClassName = cn(
            textStyles.text,
            textStyles[`style--heading-${order}`],
            styles["markdown--heading"],
            markdownColorClassName,
        );
        return `<h${order} class="${headingClassName}">${text}</h${order}>`;
    };

    renderer.paragraph = (text) => {
        const paragraphClassName = cn(
            textStyles.text,
            textStyles["style--body-1"],
            styles["markdown--body"],
            markdownColorClassName,
        );

        return `<p class="${paragraphClassName}">${text}</p>`;
    };

    renderer.strong = (text) => {
        const strongClassName = cn(textStyles["emphasis--2"]);
        return `<strong class="${strongClassName}">${text}</strong>`;
    };

    renderer.list = (body, ordered) => {
        const listClassName = cn(
            textStyles.text,
            textStyles["style--body-1"],
            styles["markdown--list"],
            markdownColorClassName,
        );
        const listType = ordered ? "ol" : "ul";
        return `<${listType} class="${listClassName}">${body}</${listType}>`;
    };

    renderer.blockquote = (quote) => {
        const blockquoteClassName = cn(
            styles["markdown--blockquote"],
            markdownColorClassName,
        );

        return `<blockquote class="${blockquoteClassName}">${quote}</blockquote>`;
    };

    renderer.image = (href, title, text) => {
        const imageClassName = cn(styles["markdown--image"]);
        const imageTitle = title ? `title="${title}"` : "";

        return `<img src="${href}" ${imageTitle} alt="${text}" class="${imageClassName}"/>`;
    };

    marked.use({ renderer });
    const html = marked.parse(markdown, {
        headerIds: false,
        mangle: false,
    });

    const wrapperRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        const links = wrapperRef.current?.querySelectorAll("a");
        const handleClick = (e: MouseEvent) => {
            if (e.currentTarget instanceof HTMLAnchorElement) {
                onLinkClick?.(e, e.currentTarget);
            }
        };
        links?.forEach((link) => {
            link.addEventListener("click", handleClick);
        });
        return () => {
            links?.forEach((link) => {
                link.removeEventListener("click", handleClick);
            });
        };
    }, [onLinkClick]);

    return (
        <div className={styles["markdown-wrapper"]} ref={wrapperRef}>
            <div
                dangerouslySetInnerHTML={{ __html: html }}
                {...props}
                className={cn(styles.markdown, className)}
                style={
                    {
                        ...props.style,
                        "--markdown-color": markdownColor,
                    } as CSSProperties
                }
            />
        </div>
    );
};
