import { AlertBasic } from "@wojo/ui";
import React from "react";

type FormErrorProps = {
    message?: string;
};
export const FormError: React.FC<FormErrorProps> = ({ message }) => {
    return (
        <AlertBasic
            style={{
                marginTop: "var(--g-spacing-xs)",
                width: "auto",
                lineHeight: "var(--g-line-height-300)",
                fontSize: "var(--g-font-size-300)",
            }}
            status="alert"
            title={
                message ??
                "Oops, something went wrong on our end. Please double check your information and try submitting again."
            }
        />
    );
};
