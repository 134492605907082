import { gtmSendEvent } from "@wojo/analytics";
import { useI18n } from "@wojo/localization";
import { Form, FormPasswordField, SubmitButton } from "@wojo/ui";
import React from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { useUpdateCustomerPasswordMutation } from "../../../generated/graphql";
import { useInternalAuth } from "../../client/context/InternalAuthContextProvider";
import { GraphqlFormError } from "../error/GraphqlFormError";
import { AuthPaths } from "../router/AuthPaths";

type FormValues = {
    password: string;
};

export const SetPasswordForm: React.FC = () => {
    const { global } = useI18n("global");
    const navigate = useNavigate();
    const { loginOrigin } = useInternalAuth();
    const [setPassword, { error }] = useUpdateCustomerPasswordMutation();
    const setPasswordForm = useForm<FormValues>({
        defaultValues: {
            password: "",
        },
        mode: "onChange",
        criteriaMode: "all",
        reValidateMode: "onChange",
    });

    return (
        <Form<FormValues>
            form={setPasswordForm}
            onSubmit={async (values) => {
                const result = await setPassword({
                    variables: {
                        input: {
                            password: values.password,
                        },
                    },
                });

                if (!result.errors) {
                    navigate(AuthPaths.SetPasswordSuccess);

                    gtmSendEvent({
                        event: "track",
                        ga4Tracking: {
                            ga4_object: "LOGIN",
                            ga4_action: "SET_PASSWORD",
                            loginId: `${loginOrigin}PasswordAccount`,
                            magicLink: "no",
                        },
                    });
                } else {
                    gtmSendEvent({
                        event: "track",
                        ga4Tracking: {
                            eventCategory: "login",
                            eventAction: `${loginOrigin} password account`,
                            eventLabel: "password submit FAIL",
                            ga4_object: "LOGIN",
                            ga4_action: "LOGIN_FAILED",
                            loginId: `${loginOrigin}PasswordAccount`,
                            magicLink: "no",
                        },
                    });
                }
            }}
        >
            <div
                style={{
                    display: "grid",
                    gridGap: "var(--g-spacing-md)",
                }}
            >
                <FormPasswordField
                    name="password"
                    enableRequirements
                    label={global.formFieldLabels.password.l()}
                    autoFocus
                    data-testid="password"
                />
                <SubmitButton
                    style={{
                        justifySelf: "start",
                    }}
                >
                    {global.genericSubmitCta.l()}
                </SubmitButton>
                <GraphqlFormError error={error} />
            </div>
        </Form>
    );
};
