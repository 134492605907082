import React from "react";
import { useLocation } from "react-router-dom";
import { SendResetPasswordLinkFooter } from "./SendResetPasswordLinkFooter";

export const LoginViewFooter: React.FC = () => {
    const { state } = useLocation();
    const email = state?.email;

    return <SendResetPasswordLinkFooter email={email!} />;
};
