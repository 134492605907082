import { ApolloError } from "@apollo/client";
import { gtmSendEvent } from "@wojo/analytics";
import { useI18n } from "@wojo/localization";
import { Form, FormTextField, SubmitButton } from "@wojo/ui";
import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useCheckCustomerEmailLazyQuery } from "../../../generated/graphql";
import { useInternalAuth } from "../../client/context/InternalAuthContextProvider";
import { GraphqlFormError } from "../error/GraphqlFormError";
import { AuthPaths } from "../router/AuthPaths";
import styles from "./VerifyEmailForm.module.scss";

type FormValues = {
    email: string;
};

export const VerifyEmailForm: React.FC = () => {
    const { global } = useI18n("global");
    const [apolloError, setApolloError] = useState<ApolloError>();
    const navigate = useNavigate();
    const { state } = useLocation();
    const { loginOrigin } = useInternalAuth();
    const [checkCustomerEmail] = useCheckCustomerEmailLazyQuery({
        fetchPolicy: "no-cache",
    });
    return (
        <Form<FormValues>
            onSubmit={async (values) => {
                gtmSendEvent({
                    event: "track",
                    ga4Tracking: {
                        eventCategory: "login",
                        eventAction: `${loginOrigin} email enter`,
                        eventLabel: "email fill submit",
                        ga4_object: "LOGIN",
                        ga4_action: "ENTER_EMAIL",
                        loginId: `${loginOrigin}PasswordAccount`,
                    },
                });

                try {
                    const { data } = await checkCustomerEmail({
                        variables: {
                            email: values.email,
                        },
                    });
                    const hasEmail = data?.checkCustomerEmail?.hasEmail;
                    const hasPassword = data?.checkCustomerEmail?.hasPassword;
                    //if the customer doesn't have an email, send to create account
                    if (!hasEmail) {
                        navigate(AuthPaths.CreateAccount, {
                            state: {
                                email: values.email,
                                tourCode: state?.tourCode,
                            },
                        });
                        return;
                    }
                    // if the customer has a password, send them to login
                    if (hasPassword) {
                        navigate(AuthPaths.Login, {
                            state: {
                                email: values.email,
                                hasPassword: true,
                            },
                        });
                        return;
                    }
                    // if the customer doesn't have a password but has email, send them the magic link directly
                    else {
                        navigate(AuthPaths.SendMagicLink, {
                            state: {
                                email: values.email,
                                hasPassword: false,
                            },
                        });
                        return;
                    }
                } catch (e) {
                    const error = e as ApolloError;
                    setApolloError(error);

                    gtmSendEvent({
                        event: "track",
                        ga4Tracking: {
                            eventCategory: "login",
                            eventAction: `${loginOrigin} email enter`,
                            eventLabel: "email check FAIL",
                            ga4_object: "LOGIN",
                            ga4_action: "LOGIN_FAILED",
                            loginId: `${loginOrigin}EmailEnter`,
                            magicLink: "no",
                        },
                    });
                }
            }}
        >
            <div className={styles["form-layout"]}>
                <FormTextField
                    name="email"
                    validation={{
                        required: global.validationErrors.emailRequired.l(),
                    }}
                    label={global.formFieldLabels.email.l()}
                    autoComplete="username"
                    autoFocus
                />
                <input
                    style={{ display: "none" }}
                    name="password"
                    type="password"
                    autoComplete="current-password"
                    aria-hidden="true"
                    tabIndex={-1}
                    readOnly
                />
                <SubmitButton className={styles["submit-button"]}>
                    {global.genericSubmitCta.l()}
                </SubmitButton>
                <GraphqlFormError error={apolloError} />
            </div>
        </Form>
    );
};
