import React from "react";
import { Checkbox, CheckboxProps } from "../../checkbox";
import { FormField, FormFieldProps } from "../form-field";

export type FormCheckboxProps = FormFieldProps<CheckboxProps>;

export const FormCheckbox: React.FC<FormCheckboxProps> = ({
    validation,
    name,
    ...checkboxProps
}) => {
    return (
        <FormField name={name} validation={validation}>
            <Checkbox {...checkboxProps} name={name} />
        </FormField>
    );
};
