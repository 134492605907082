import classNames from "clsx";
import { Text } from "../../text";
import { AlertIcon } from "../shared/AlertIcon";
import { AlertProps } from "../shared/AlertProps";
import styles from "./AlertBasic.module.scss";

export type AlertBasicProps = Omit<AlertProps, "title"> & {
    /**
     * This is the only content that will be displayed to the user
     */
    title: React.ReactNode;
};

export const AlertBasic: React.FC<AlertBasicProps> = ({
    status = "success",
    title,
    iconAltText,
    className = "",
    ...otherProps
}) => {
    const alertClassName = classNames(
        styles.wrapper,
        styles[`basic--${status}`],
        className,
    );
    return (
        <Text.Body emphasis="1" className={alertClassName} {...otherProps}>
            <AlertIcon status={status} altText={iconAltText} />
            <span>{title}</span>
        </Text.Body>
    );
};
