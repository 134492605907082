import { Text } from "@wojo/ui";
import React from "react";
import { VerifyEmailForm } from "../forms/VerifyEmailForm";
import { Title } from "../title";

export const VerifyEmailCheckoutView: React.FC = () => {
    return (
        <>
            <Title>Before you continue to checkout…</Title>
            <Text
                style={{
                    marginBottom: "var(--g-spacing-sm)",
                }}
            >
                Log in or register with your email address.
            </Text>
            <VerifyEmailForm />
        </>
    );
};
