"use client";

import { type WebformSchema } from "./webformSchema";
import { Cookie, getClientId, type WebConversionCookie } from "@wojo/analytics";
import Cookies from "js-cookie";

export const useSubmitWebform = (gcDepartureId?: string) => {
    return (data: WebformSchema) => {
        const cookie: WebConversionCookie = JSON.parse(
            Cookies.get(Cookie.webConversion) || "{}",
        );

        const body: WebformSchema = {
            utm_campaign: cookie.UTMCampaign,
            utm_content: cookie.UTMContent,
            utm_group: cookie.UTMGroup,
            utm_medium: cookie.UTMMedium,
            utm_source: cookie.UTMSource,
            utm_term: cookie.UTMTerm,
            gaClientId: getClientId(),
            gcDepartureId,
            ...data,
        };

        return fetch("/api/webform", {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(body),
        });
    };
};
