"use client";
import React from "react";
import { TextField, TextFieldProps } from "../../text-fields/text-field";
import {
    FormFieldController,
    FormFieldControllerProps,
} from "../form-field-controller";
import { getMaxLength } from "../hooks";

export type FormTextFieldProps = FormFieldControllerProps<TextFieldProps>;

export const FormTextField: React.FC<FormTextFieldProps> = ({
    validation,
    name,
    ref,
    maxLength,
    ...textFieldProps
}) => {
    const max = getMaxLength(validation?.maxLength) ?? maxLength;

    return (
        <FormFieldController name={name} validation={validation}>
            <TextField
                fieldId={name}
                maxLength={max}
                forceControlledMode
                {...textFieldProps}
            />
        </FormFieldController>
    );
};
