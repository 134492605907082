/* eslint-disable react/display-name */
"use client";

import cn from "clsx";
import { forwardRef, useId } from "react";
import { FiCheck } from "react-icons/fi";
import {
    FieldDescription,
    getFieldDescriptionId,
} from "../form-fields/field-description";
import { FieldLabel } from "../form-fields/field-label";
import styles from "./Checkbox.module.scss";

export type CheckboxProps = React.InputHTMLAttributes<HTMLInputElement> & {
    /**
     * Placement of `labelDetail`
     * @default "right"
     */
    detailPosition?: "below" | "right";
    /**
     * Is the checkbox disabled?
     * @default false
     */
    disabled?: boolean;
    /**
     * Error text describing what's wrong with the user's input
     */
    errorText?: string;
    /**
     * Hides any error text that would be displayed, while still displaying error styling
     */
    hideErrorText?: boolean;
    /**
     * Hides the required indicator
     * @default false
     */
    hideRequired?: boolean;
    /**
     * Label for this checkbox
     */
    label: React.ReactNode;
    /**
     * Secondary text related to `label`
     */
    labelDetail?: React.ReactNode;
    /**
     * Is this checkbox required?
     * @default false
     */
    required?: boolean;
    /**
     * Styles to apply to the wrapper element
     */
    wrapperClassName?: string;
    /** Additional elements to display with this option outside of the label tag */
    moreDetails?: React.ReactNode;
};

export const Checkbox = forwardRef<HTMLInputElement, CheckboxProps>(
    (
        {
            detailPosition = "right",
            disabled,
            errorText,
            id,
            hideErrorText,
            hideRequired,
            label,
            labelDetail,
            onChange,
            onClick,
            required,
            wrapperClassName,
            moreDetails,
            ...inputProps
        },
        ref,
    ) => {
        const hasError = !!errorText;
        const fallbackId = useId();
        const checkboxId = id || fallbackId;
        return (
            <>
                <div className={wrapperClassName}>
                    <FieldLabel
                        className={cn(
                            styles["label-wrapper"],
                            styles["class-name"],
                            { [styles["label-wrapper--disabled"]]: disabled },
                            styles[`label-wrapper--detail-${detailPosition}`],
                        )}
                        disabled={disabled}
                        fieldId={checkboxId}
                        hasError={hasError}
                    >
                        <span>
                            <span className={styles["indicator-wrapper"]}>
                                <input
                                    aria-describedby={
                                        errorText
                                            ? getFieldDescriptionId(checkboxId)
                                            : undefined
                                    }
                                    aria-disabled={disabled || undefined}
                                    aria-invalid={hasError || undefined}
                                    aria-required={required || undefined}
                                    className={cn(
                                        "visually-hidden",
                                        styles["input-checkbox"],
                                    )}
                                    onClick={(e) => {
                                        if (disabled) {
                                            e.preventDefault();
                                        } else {
                                            onClick?.(e);
                                        }
                                    }}
                                    onChange={(e) => {
                                        if (disabled) {
                                            e.preventDefault();
                                        } else {
                                            onChange?.(e);
                                        }
                                    }}
                                    onKeyDown={(e) => {
                                        if (e.key === "Enter" && !disabled) {
                                            e.preventDefault();
                                            const target =
                                                e.target as HTMLInputElement;
                                            onChange?.({
                                                ...e,
                                                target: {
                                                    ...target,
                                                    checked: !target.checked,
                                                },
                                            });
                                        }
                                    }}
                                    id={checkboxId}
                                    ref={ref}
                                    type="checkbox"
                                    {...inputProps}
                                />
                                <span className={styles.indicator}>
                                    <FiCheck className={styles.icon} />
                                </span>
                            </span>
                            <span
                                className={cn({
                                    [styles["label-text--required"]]:
                                        required && !hideRequired,
                                })}
                            >
                                {label}
                            </span>
                            {required && !hideRequired && (
                                <span
                                    aria-hidden="true"
                                    className={styles["required-marker"]}
                                >
                                    required
                                </span>
                            )}
                        </span>
                        {labelDetail && (
                            <span
                                className={cn(
                                    styles["label-detail"],
                                    styles[`label-detail--${detailPosition}`],
                                )}
                            >
                                {labelDetail}
                            </span>
                        )}
                    </FieldLabel>
                    {moreDetails && (
                        <div className={styles["description-wrapper"]}>
                            {moreDetails}
                        </div>
                    )}
                </div>
                {errorText && (
                    <div
                        className={cn(styles["description-wrapper"], {
                            [styles["description-wrapper--disabled"]]: disabled,
                        })}
                    >
                        <FieldDescription
                            errorText={errorText}
                            hideErrorText={hideErrorText}
                            fieldId={checkboxId}
                        />
                    </div>
                )}
            </>
        );
    },
);
