import { useI18n } from "@wojo/localization";
import { Button, Text } from "@wojo/ui";
import React from "react";
import { FiCheckCircle } from "react-icons/fi";
import { useLocation } from "react-router-dom";
import { useInternalAuth } from "../../client/context/InternalAuthContextProvider";
import { Title } from "../title";
import styles from "./SendLinkView.module.scss";
import { SendResetPasswordOrMagicLink } from "./SendResetPasswordOrMagicLink";

export const SendResetPasswordView: React.FC = () => {
    const { state } = useLocation();
    const email = state?.email;
    const { setIsModalOpen, displayType } = useInternalAuth();
    const { global } = useI18n("global");

    return (
        <SendResetPasswordOrMagicLink
            email={email}
            emailTemplateId={
                process.env.NEXT_PUBLIC_RESET_PASSWORD_TEMPLATE_ID ?? ""
            }
            isResetPassword
        >
            <Title>Reset your account password</Title>
            <Text className={styles["success-text"]}>
                <FiCheckCircle
                    style={{ marginTop: "var(--g-spacing-xs)" }}
                    aria-hidden
                />
                <span>
                    We&apos;ve sent a link to reset your password to {email}
                </span>
            </Text>
            <Text className={styles["success-description"]}>
                {global.auth.resetPasswordSuccessDescription.l()}
            </Text>
            {displayType === "MODAL" && (
                <Button onClick={() => setIsModalOpen?.(false)}>Close</Button>
            )}
        </SendResetPasswordOrMagicLink>
    );
};
