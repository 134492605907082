"use client";
import cn from "clsx";
import {
    DetailedHTMLProps,
    forwardRef,
    InputHTMLAttributes,
    ReactNode,
    useState,
} from "react";
import { FiAlertCircle } from "react-icons/fi";
import { LoadingSpinner } from "../../loading-spinner";
import styles from "./Textbox.module.scss";
import { TextFieldAdornment } from "./TextFieldAdornment";

export type TextboxProps = InnerProps & {
    /**
     * Icon, text, etc to display on the inner right of the input
     */
    adornRight?: ReactNode;
    /**
     * Icon, text, etc to display on the inner left of the input
     */
    adornLeft?: ReactNode;
    /**
     * Whether the field has an error.
     */
    hasError?: boolean;
    /**
     * Whether the field is required.
     */
    required?: boolean;

    /** Displays a spin component in place of the adornRight while active */
    loading?: boolean;
    /** Optional classname for the textbox parent element  */
    wrapperClassName?: string;
};
type InnerProps = DetailedHTMLProps<
    InputHTMLAttributes<HTMLInputElement>,
    HTMLInputElement
>;
export const Textbox = forwardRef<HTMLInputElement, TextboxProps>(
    function Textbox(
        {
            className,
            adornLeft,
            adornRight,
            hasError,
            loading,
            onFocus,
            onBlur,
            disabled,
            wrapperClassName,
            children,
            required,
            ...restProps
        },
        ref,
    ) {
        const [focused, setFocused] = useState(false);

        const classNames = cn([
            styles.container,
            { [styles["container--focused"]]: !disabled && focused },
            {
                [styles["container--error"]]: hasError && !disabled && !focused,
                [styles["container--not-disabled"]]: !disabled,
                [styles["container--disabled"]]: disabled,
            },
            wrapperClassName,
        ]);

        return (
            <div
                className={classNames}
                id={restProps.id ? `${restProps.id}_wrapper` : undefined}
            >
                <TextFieldAdornment side="left">{adornLeft}</TextFieldAdornment>
                <input
                    aria-invalid={hasError}
                    aria-disabled={disabled}
                    aria-required={!!required || undefined}
                    className={cn(className, styles.input, {
                        [styles["disabled"]]: disabled,
                        [styles["input--has-left"]]: !!adornLeft,
                        [styles["input--has-right"]]: !!adornRight,
                    })}
                    readOnly={disabled}
                    {...restProps}
                    ref={ref}
                    onFocus={(e) => {
                        setFocused(true);
                        onFocus?.(e);
                    }}
                    onBlur={(e) => {
                        setFocused(false);
                        onBlur?.(e);
                    }}
                />
                <TextFieldAdornment side="right">
                    {/* Show spinner if loading, 
                            else show error icon if not disabled and has error,
                                else show adornRight */}
                    {loading ? (
                        <LoadingSpinner
                            size="var(--g-size-xxs)"
                            data-testid="textbox-spin"
                            aria-label="Loading"
                        />
                    ) : hasError && !disabled ? (
                        <FiAlertCircle size={16} />
                    ) : (
                        adornRight
                    )}
                </TextFieldAdornment>
                {children}
            </div>
        );
    },
);
