"use client";

import React, { ChangeEvent, ReactNode } from "react";
import {
    FieldDescription,
    getFieldDescriptionId,
} from "../../form-fields/field-description";
import { FieldLabel } from "../../form-fields/field-label";
import { FieldProps } from "../../form-fields/field-props";
import { FieldWrapper } from "../../form-fields/field-wrapper";
import { Textbox, TextboxProps } from "../textbox";

export type TextFieldProps = FieldProps &
    Omit<TextboxProps, "hasError"> & {
        adornLeft?: ReactNode;
        /**
         * Icon, button, loader, etc to display on the inner right of the input
         */
        adornRight?: ReactNode;
        /**
         * Force controlled mode sets the initial value to empty string
         */
        forceControlledMode?: boolean;
    };
export const TextField = React.forwardRef<HTMLInputElement, TextFieldProps>(
    (
        {
            label,
            hideLabel,
            required = false,
            errorText,
            descriptionText,
            hideErrorText,
            value,
            forceControlledMode,
            defaultValue,
            maxLength,
            onChange,
            showOptionalValidationText,
            hideMarker,
            hideErrorIcon,
            fieldId,
            disabled,
            ...inputProps
        },
        ref,
    ) => {
        const hasError = !!errorText;
        const val = forceControlledMode || value ? value ?? "" : undefined;
        const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
            onChange?.(e);
        };

        return (
            <FieldWrapper
                disabled={disabled}
                data-has-error={hasError}
                fieldLabel={
                    <FieldLabel
                        hideLabel={hideLabel}
                        required={required}
                        fieldId={fieldId}
                        hideMarker={hideMarker}
                        showOptionalValidationText={showOptionalValidationText}
                        hasError={hasError}
                        disabled={disabled}
                    >
                        {label}
                    </FieldLabel>
                }
                fieldDescription={
                    <FieldDescription
                        fieldId={fieldId}
                        errorText={errorText}
                        hideErrorIcon={hideErrorIcon}
                        hideErrorText={hideErrorText}
                        disabled={disabled}
                    >
                        {descriptionText}
                    </FieldDescription>
                }
            >
                <Textbox
                    aria-describedby={getFieldDescriptionId(fieldId)}
                    {...inputProps}
                    required={required}
                    ref={ref}
                    id={fieldId}
                    hasError={hasError}
                    value={val}
                    onChange={handleChange}
                    disabled={disabled}
                    maxLength={maxLength}
                />
            </FieldWrapper>
        );
    },
);

TextField.displayName = "TextField";
