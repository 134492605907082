"use client";
import { forwardRef, LegacyRef, Ref, useState } from "react";
import { FiEye, FiEyeOff } from "react-icons/fi";
import { Button } from "../../button";
import { TextField, TextFieldProps } from "../text-field";

export type PasswordFieldProps = Omit<TextFieldProps, "adornRight" | "mask"> & {
    ref?: LegacyRef<HTMLInputElement> | Ref<HTMLInputElement>;
};

export const PasswordField = forwardRef<HTMLInputElement, PasswordFieldProps>(
    ({ type = "password", ...fieldProps }, ref) => {
        const [inputType, setInputType] = useState(type);

        return (
            <TextField
                {...fieldProps}
                ref={ref}
                type={inputType}
                adornRight={
                    <Button
                        variant="quiet"
                        type="button"
                        adornRight={
                            inputType === "password" ? <FiEye /> : <FiEyeOff />
                        }
                        onClick={() =>
                            setInputType(
                                inputType === "password" ? "text" : "password",
                            )
                        }
                    >
                        {inputType === "password" ? "Show" : "Hide"}
                    </Button>
                }
            />
        );
    },
);

PasswordField.displayName = "PasswordField";
