"use client";
import React, { useId, useState } from "react";
import {
    PasswordField,
    PasswordFieldProps,
} from "../../text-fields/password-field";
import {
    FormFieldController,
    FormFieldControllerProps,
} from "../form-field-controller";
import { FormPasswordRequirements } from "./FormPasswordRequirements";

export type FormPasswordFieldProps =
    FormFieldControllerProps<PasswordFieldProps> & {
        /**
         * Enable default password requirements
         */
        enableRequirements?: boolean;
    };

export const FormPasswordField: React.FC<FormPasswordFieldProps> = ({
    validation,
    name,
    ref,
    enableRequirements = false,
    className,
    ...textFieldProps
}) => {
    const requirementsId = useId();
    const [requirementsVisible, setRequirementsVisible] = useState(false);
    const passwordValidation = enableRequirements
        ? {
              required: "Please enter a password",
              validate: {
                  minimum: (value: string) => value.length >= 6,
                  capitals: (value: string) => /[A-Z]/.test(value),
                  characters: (value: string) =>
                      /[!@#$%^&*(),.?":{}|<>0-9]/.test(value),
              },
          }
        : validation;
    return (
        <div className={className}>
            <FormFieldController name={name} validation={passwordValidation}>
                <PasswordField
                    fieldId={name}
                    {...textFieldProps}
                    aria-describedby={requirementsId}
                    onFocus={(e) => {
                        textFieldProps.onChange?.(e);
                        setRequirementsVisible(true);
                    }}
                    forceControlledMode
                    hideErrorText={enableRequirements}
                />
            </FormFieldController>
            <FormPasswordRequirements
                id={requirementsId}
                name={name}
                visible={requirementsVisible && enableRequirements}
            />
        </div>
    );
};
